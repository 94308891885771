import React from 'react';
import ItemColumn from './ItemColumn';
import { Tag, ProductImage, Skeleton, Checkbox } from '@layerise/design-core';
import { ProductListingQuery } from 'types/typed-document-nodes';
import { AssistantTag } from 'components/tag/AssistantTag';
import { TagList } from 'components/tag/TagList';
import { StatusTag } from 'components/tag/StatusTag';
import { CheckboxWrapper } from './styles';
import { MetaInfo } from 'components/table/MetaInfo';
import { AssistantConnectionsTag } from 'components/connections/AssistantConnectionsTag';

interface ColumnProps {
  product: ProductListingQuery['products'][number];
  onClick: (itemId: string) => void;
  selected: boolean;
  geoFencingEnabled: boolean;
}

interface HeaderProps {
  onSelectAll: () => void;
  selected: boolean;
}

export const productLoadingView = [
  {
    id: 'skeleton',
    columns: [
      { id: '0', width: 16, content: <Skeleton height="16px" width="16px" /> },
      { id: '1', width: 48, content: <Skeleton height="32px" width="32px" /> },
      {
        id: '2',
        content: <Skeleton />,
      },
      {
        id: 'status',
        content: <Skeleton />,
      },
      {
        id: '3',
        content: <Skeleton />,
      },
      {
        id: '4',
        content: <Skeleton />,
      },
      {
        id: '5',
        content: <Skeleton />,
      },
      {
        id: '6',
        content: <Skeleton />,
      },
    ],
  },
];

export const productHeaderItems = ({ onSelectAll, selected }: HeaderProps) => [
  {
    id: '0',
    visible: true,
    content: (
      <CheckboxWrapper>
        <Checkbox
          onChange={() => {
            onSelectAll();
          }}
          value={`all`}
          checked={selected}
          name={`all`}
          size="sm"
        />
      </CheckboxWrapper>
    ),
  },
  { id: '1', title: 'name' },
  {
    id: '2',
  },
  {
    id: 'status',
    title: 'Status',
  },
  {
    id: '3',
    title: 'Category',
  },
  {
    id: '4',
    title: 'Tags',
  },
  {
    id: '5',
    title: 'Assistants',
  },
  {
    id: '6',
    title: 'Updated by',
    padding: '0 8px',
    width: '110',
    align: 'left',
  },
];

export const productColumns = ({ product, onClick, selected, geoFencingEnabled }: ColumnProps) => [
  {
    id: '0',
    width: 16,
    content: (
      <CheckboxWrapper>
        <Checkbox
          onChange={() => {
            onClick(product.id);
          }}
          value={product.id}
          checked={selected}
          name={product.id}
          size="sm"
        />
      </CheckboxWrapper>
    ),
  },
  {
    id: '1',
    width: 64,
    content: (
      <ItemColumn itemId={product.id} onClick={onClick}>
        <ProductImage height={40} borderRadius={4} border src={product.image?.dynamic || ''} />
      </ItemColumn>
    ),
  },
  {
    id: '2',
    content: (
      <ItemColumn itemId={product.id} onClick={onClick}>
        {product.title}
      </ItemColumn>
    ),
    fontWeight: 600,
    fontColor: 'var(--color-N600)',
    fontSize: 14,
  },
  {
    id: 'status',
    content: (
      <ItemColumn itemId={product.id} onClick={onClick}>
        <StatusTag archived={product.archived} published={product.published} />
      </ItemColumn>
    ),
  },
  {
    id: '3',
    content: (
      <ItemColumn itemId={product.id} onClick={onClick}>
        {product.category?.title && <Tag variant="info">{product.category.title}</Tag>}
      </ItemColumn>
    ),
  },
  {
    id: '4',
    content: (
      <ItemColumn itemId={product.id} onClick={onClick}>
        <TagList wrap viewOnly multiLine={false} tags={product.tags} />
      </ItemColumn>
    ),
  },
  {
    id: '5',
    content: geoFencingEnabled ? (
      <AssistantConnectionsTag entityName="Product" assistants={product.assistants} />
    ) : product.assistants?.length ? (
      <AssistantTag name={product.assistants[0]?.name} />
    ) : (
      <>No assistant</>
    ),
  },
  {
    id: '6',
    content: (
      <ItemColumn itemId={product.id} onClick={onClick}>
        <MetaInfo transactionLogs={product.transactionLogs} updatedAt={product.updatedAt} selected={selected} />
      </ItemColumn>
    ),
  },
];
