import styled, { css } from 'styled-components';

export const FeedbackContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 16px;
`;

export const FeedbackTitle = styled.span<{ positive?: boolean }>`
  background-color: ${props => (props.positive ? '#E5F4F2' : '#FFF9E6')};
  color: ${props => (props.positive ? '#1A2F52' : '#483434')};
  border-radius: 5px;
  font-size: 16px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: ${props => (props.positive ? 'positive' : 'negative')};

  svg path {
    fill: ${props => (props.positive ? '#1A2F52' : '#483434')};
  }

  svg {
    height: 12px;
    margin-right: 4px;
  }
`;

export const Total = styled.p`
  color: var(--color-N500);
  font-size: 14px;
  width: 100%;
  display: inline-block;
  grid-area: total;
`;

export const Feedback = styled.p<{ positive?: boolean }>`
  background-color: ${props => (props.positive ? '#E5F4F2' : '#FFF9E6')};
  color: ${props => (props.positive ? '#1A2F52' : '#483434')};
  border-radius: 5px;
  font-size: 16px;
  padding: 4px 8px;
  display: block;

  svg path {
    fill: ${props => (props.positive ? '#1A2F52' : '#483434')};
  }

  svg {
    height: 12px;
    margin-right: 4px;
  }
`;

export const FeedbackGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  margin: 32px auto;
  max-width: 960px;
  width: 100%;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
`;

export const NavItem = styled.li<{ selected?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin: 8px;
  border-radius: 5px;
  font-weight: 500;

  p {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0;
    text-align: right;
  }
  &:hover {
    cursor: pointer;
    background: #e6e9ff;
  }

  ${props =>
    props.selected &&
    css`
      background: #e6e9ff;
    `};
`;
