import React from 'react';
import styled from 'styled-components';

type ContentAlign = 'left' | 'right' | 'center';

interface ItemColumnProps {
  itemId: string;
  children: React.ReactNode;
  contentAlign?: ContentAlign;
  onClick: (itemId: string) => void;
}

const ClickableArea = styled.div<{ contentAlign?: ContentAlign }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${props => props.contentAlign || 'left'};

  img {
    max-height: 60px;
  }
`;

const ItemColumn = ({ itemId, contentAlign, onClick, children }: ItemColumnProps) => {
  return (
    <ClickableArea onClick={() => onClick(itemId)} contentAlign={contentAlign}>
      {children}
    </ClickableArea>
  );
};

export default ItemColumn;
